import {FullWidthCarousel, useWindowSize} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type FullWidthCarouselContentItem = IContentItem<{
	autoplay: Elements.MultipleChoiceElement
	images: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
}>

export const FullWidthCarouselBlock: Block<FullWidthCarouselContentItem> = ({
	block,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call -- TODO: fix Component Library types
	const size = useWindowSize() as {isMobile: boolean}

	return (
		<div data-kontent-element-codename={block.system.codename}>
			<FullWidthCarousel
				autoplay={block.elements.autoplay[0]?.codename === 'yes'}
			>
				{block.elements.images.map((image, index) => {
					const mobileImage = block.elements.mobileImage[index]
					const selectedImage =
						size.isMobile && mobileImage ? mobileImage : image

					return (
						<Image
							alt={selectedImage.description || ''}
							className="max-h-screen w-full object-cover"
							height={selectedImage.height || 0}
							key={selectedImage.url || index}
							loader={loadImageFromKontentAI}
							quality={100}
							src={selectedImage.url || ''}
							width={selectedImage.width || 0}
						/>
					)
				})}
			</FullWidthCarousel>
		</div>
	)
}
